import { render, staticRenderFns } from "./Invitation.vue?vue&type=template&id=650f416b&scoped=true&"
import script from "./Invitation.vue?vue&type=script&lang=js&"
export * from "./Invitation.vue?vue&type=script&lang=js&"
import style0 from "./Invitation.vue?vue&type=style&index=0&id=650f416b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "650f416b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
